<template>
  <div class="info">
    <el-scrollbar>
      <TaskInfo :pkTaskId="$route.params.projectId - 0"></TaskInfo>
    </el-scrollbar>
  </div>
</template>

<script>
import TaskInfo from '../../TaskManager/TaskInfo.vue'
export default {
  components: { TaskInfo },
  data() {
    return {}
  },
  methods: {},
  created() {
    console.log(this.$route.params.projectId)
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.info {
  width: 100%;
  // padding: 0 10px;
  height: 100%;
  .el-scrollbar {
    height: 100%;
  }

  .el-scrollbar ::v-deep.el-scrollbar__wrap {
    overflow: scroll;
    height: 100% !important;
    overflow-x: hidden !important;
    padding: 10px 15px;
    padding-bottom: 20px;
  }
}
</style>
